import { Injectable } from '@angular/core';
import { NewsModel } from '../models/news.model';

@Injectable({
  providedIn: 'root'
})
export class NewsDataService {

  constructor() { }

  filter(news: Array<NewsModel>, preview: boolean): Array<NewsModel> {
    return news.filter(item => {
      if (preview) { return true; }
      if (item.hasOwnProperty('settings') && item.settings.hide) { return false; }

      if (item.hasOwnProperty('display')) {
        const startDate = Date.parse(item.display.from.replace(/ /g, 'T'));
        const finishDate = Date.parse(item.display.to.replace(/ /g, 'T'));
        const now = Date.now();

        if (isNaN(startDate) && isNaN(finishDate)) {
          return true;
        }
        if (isNaN(startDate) || isNaN(finishDate)) {
          return false;
        }

        return startDate < now && finishDate > now;
      } else {
        return true;
      }
    });
  }

  sort(news: Array<NewsModel>): Array<NewsModel> {
    return news.sort((a, b) => (a.order < b.order) ? 1 : -1);
  }

  featured(news: Array<NewsModel>): Array<NewsModel> {
    return news.filter(item => item.hasOwnProperty('settings') && item.settings.featured);
  }

  limited(item: NewsModel): boolean {
    return item.hasOwnProperty('valid') && !!item.valid.from && !!item.valid.to;
  }
}
